<template>
  <Navbar />

  <div class="flex flex-col justify-center space-y-8 space-x-2 px-4 mt-10 mb-8">
    <div class="card lg:card-side">
      <div class="card-body md:w-2/5">
        <h2 class="card-title mb-8">
          <span class="leading-10 text-5xl">
            Hi! 👋
            <br><br>
          </span>
          <span class="leading-10 text-5xl">
            So, you want to know more about us? 🤩
          </span>
        </h2>
        <p>
          We are gamers, at your service. We bring years of our gaming experience at semi-professional levels to help
          fellow gamers climb the ladder and reach the top.
        </p>
        <p class="mt-5">
          A little back story about us. We started playing Counter Strike Global Offensive several years back.
          We were Silvers <span style="font-size: 2rem">🥈</span> and in mere 8 months, ranked up to Global Elite
          <span style="font-size: 2rem">🎖</span>
        </p>
        <p>
          It was a fun journey for us. We definitely had used in-house tools. And then we got into other competitive games
          like DOTA and LOL (League of Legends). We found the same vibe within the community. And then came Valorant.  But the story was same.
          There were too many <span class="font-bold">automated coaching tools</span> but not a single <span class="italic">
          educating tool.</span>
        </p>
        <p class="mt-4">
          <span class="text-2xl font-bold">What's the difference? Everyone has the same stats 🥱</span>
          <br><br>
          The baseline difference lies in <span class="italic">what they show you</span> and by that
          we mean if they are making you follow what they find is the meta (most effective tactics available).
          We provide you with tools and information to decide how you would like to improve yourself.
          <br><br>
          Have you ever wondered about the trade-potential your team mate had when you died?
          We definitely know that it is an important aspect of the game. We provide you with
          <span class="text-secondary">Death Snapshot heatmap under Round Snippets</span> to assess the same.
          And that's just one of the many tools we provide.<br>
          We are consistently working towards improving your experience and would appreciate all your feedback.
        </p>
        <p class="mt-5">
          With our upcoming features and premium offerings, we intend to solidify the line we drew. We are in this with
          you. We want you to explore yourself and set bar for yourself, not someone else doing this for you.
          You need assistance, we are here. You need to be spoon fed, we are sorry! <br><br>
          Feel free to reach out to us at our Discord if you have any questions or concerns
        </p>
      </div>
      <div class="mt-5 mb-5 px-5">
        <img class="w-full"  src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Vision.svg">
      </div>
    </div>

  </div>

  <Footer />
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {Footer, Navbar}
}
</script>